import React, { useState, CSSProperties } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from 'gatsby'
import { FaHackerNews, FaStackOverflow, FaGithub, FaEthereum } from 'react-icons/fa'
import { FiMessageSquare } from 'react-icons/fi'
import { GoBeaker } from 'react-icons/go'

const IndexPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Layout>
      <SEO title="Jason Sebring :: I code, mostly." description="Programmer currently focused on React, IOS/Andriod, Web, AWS" />
      <div style={styles.links}>
        <a target="_blank" style={styles.link} href="https://github.com/sebringj"><FaGithub color="#fff" size={32} /></a>
        <a target="_blank"  style={styles.link} href="https://stackoverflow.com/users/775516/jason-sebring?tab=profile"><FaStackOverflow color="#fff" size={32} /></a>
        <a target="_blank"  style={styles.link} href="https://news.ycombinator.com/user?id=sebringj"><FaHackerNews color="#fff" size={32} /></a>
        <a style={styles.link} href="/pay"><FaEthereum color="#fff" size={32} /></a>
        {/*<Link to="/contact"><FiMessageSquare color="#fff" size={32} /></Link>*/}
      </div>
    </Layout>
  )
}

const styles: {
  links: CSSProperties,
  link: CSSProperties
} = {
  links: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    width: '100vw',
    height: '100vh',
  },
  link: {

  }
};

export default IndexPage
